import each from 'lodash/each';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import { backOffice } from '@emobg/web-api-client';
import {
  INITIAL_STATUS, camelCaseKeys, createCustomStore, withScopes,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { setError } from '@/store/setError';

const fleetInvoicingApi = backOffice.fleetInvoicing;

export const SCOPES = Object.freeze({
  INVOICE_PLAN: 'invoicePlan',
  issuedInvoices: 'issuedInvoices',
});

const initialInvoicePlan = {
  uuid: null,
  contractUuid: null,
  billingReference: null,
  firstInvoiceDate: null,
  period: null,
  netDays: null,
  purchaseOrderNumber: null,
  createdAt: null,
  customer: {
    name: null,
    email: null,
    address: {
      city: null,
      postalCode: null,
      country: null,
      address1: null,
      address2: null,
      address3: null,
    },
    phone: null,
    cif: null,
    otherDetails: null,
  },
};

const invoicePlanModel = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, ({
  namespace: true,
  state: {
    invoicePlan: initialInvoicePlan,
    FORM_STATUS: { ...INITIAL_STATUS },
    invoicePlanFormOpen: false,
  },
  mutations: {
    setInvoicePlan(state, newInvoicePlan) {
      const invoicePlan = { ...camelCaseKeys(newInvoicePlan) };

      state.invoicePlan = invoicePlan;
    },
    openInvoicePlanModalForm(state) {
      state.invoicePlanFormOpen = true;
    },
    closeInvoicePlanModalForm(state) {
      state.invoicePlanFormOpen = false;
    },
    resetInvoicePlanForm(state) {
      state.invoicePlan = initialInvoicePlan;
    },
    setFormStatus(state, value) {
      const states = keys(state.FORM_STATUS);

      if (includes(states, value)) {
        each(states, key => {
          state.FORM_STATUS[key] = key === value;
        });
      }
    },
    setError,
  },
  actions: {
    async getInvoicePlan({ commit }, invoicePlanUuid) {
      try {
        commit('setFormStatus', 'LOADING');
        commit('openInvoicePlanModalForm');

        const invoicePlanData = await fleetInvoicingApi.getInvoicePlan(invoicePlanUuid);

        commit('setInvoicePlan', invoicePlanData.data);
        commit('setFormStatus', 'LOADED');
      } catch (error) {
        commit('setFormStatus', 'ERROR');
        commit(DOMAINS_MODEL.app.messages.errors.throwError, error, { root: true });
        throw error;
      }
    },
    async getIssuedInvoices({ commit }, invoicePlanUuid) {
      return runAsyncFlow(commit, {
        scope: SCOPES.issuedInvoices,
        request: fleetInvoicingApi.getIssuedInvoices,
        params: [invoicePlanUuid],
      });
    },
    async saveInvoicePlan({ commit }, { invoicePlanUuid, request }) {
      try {
        commit('setFormStatus', 'LOADING');

        const putInvoicePlanReq = {
          scope: SCOPES.INVOICE_PLANS,
          request: fleetInvoicingApi.putInvoicePlan,
          params: [invoicePlanUuid, request],
        };

        const postInvoicePlanReq = {
          scope: SCOPES.INVOICE_PLANS,
          request: fleetInvoicingApi.postInvoicePlan,
          params: [request],
        };

        const createOrEditConfig = invoicePlanUuid
          ? putInvoicePlanReq
          : postInvoicePlanReq;

        await runAsyncFlow(commit, createOrEditConfig);
        commit('setFormStatus', 'LOADED');
      } catch (error) {
        commit('setFormStatus', 'ERROR');
        commit(DOMAINS_MODEL.app.messages.errors.throwError, error, { root: true });
        throw error;
      }
    },
    resetInvoicePlan({ commit }) {
      commit('resetInvoicePlanForm');
    },
  },
})));

export default invoicePlanModel;
